import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-to-clipboard"
export default class extends Controller {
  async copy(click) {
    const copyText = click.target.dataset.copyText;
    await navigator.clipboard.writeText(copyText);
    alert(`Copied and ready to paste!\n\n${copyText}`);
  }
}
