import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sign-up-form"
export default class extends Controller {
  submit() {
    const loader = document.getElementById("loader");
    const submitButton = document.getElementById("submit");
    const form = document.getElementById("sign_up");
    form.classList.add("visually-hidden");
    loader.classList.remove("visually-hidden");
    submitButton.classList.add("disabled");
    if (typeof dataLayer !== "undefined") {
      dataLayer.push({"event":"sign_up"});
    } else {
      console.log("No dataLayer found");
    }
  }
}
