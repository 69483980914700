import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar-search-form"
export default class extends Controller {
  submit(event) {
    const loader = document.getElementById('recipeScraperLoader');
    const turboFrame = document.querySelector('turbo-frame#recipe_result');

    if (turboFrame) {
      turboFrame.classList.add('visually-hidden');
    }

    loader.classList.remove('visually-hidden');
  }

  submitEnd() {
    // hide loader
    const loader = document.getElementById('recipeScraperLoader');
    loader.classList.add('visually-hidden');

    // show turbo-frame
    const turboFrame = document.querySelector('turbo-frame#recipe_result');
    if (turboFrame) {
      turboFrame.classList.remove('visually-hidden');
    }
  }
}
