import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [ "meal", "date" ]

  dragStart(event) {
    const dragSource = event.target.closest("[draggable=true]");
    event.dataTransfer.setData("application/drag-key", dragSource.getAttribute("data-meal-id"));
    event.dataTransfer.setData("application/owner-key", dragSource.getAttribute("data-owner-id"));
    event.dataTransfer.setData("application/user-meal-by-date-key", dragSource.getAttribute("data-user-meal-by-date-id"));
    event.dataTransfer.effectAllowed = "copy";
    dragSource.classList.add("dragging");
  }

  dragEnd(event) {
    const dragSource = event.target;
    dragSource.classList.remove("dragging")
  }
  
  dragEnter(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }
  
  dragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }

  dragLeave(event) {
    event.preventDefault();
  }

  drop(event) {
    event.preventDefault();

    const dragKey = event.dataTransfer.getData("application/drag-key");
    const ownerKey = event.dataTransfer.getData("application/owner-key");
    const userMealByDateKey = event.dataTransfer.getData("application/user-meal-by-date-key");
    const dropTarget = event.target.closest("td");
    const targetDate = dropTarget.getAttribute("data-target-date");
    const calendarParent = document.getElementById("user_meals_by_dates")
    const calendarStartDate = calendarParent.getAttribute("data-calendar-start-date");
   
    fetch("/user_meals_by_dates/drop", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({
        calendar_start_date: calendarStartDate,
        dropped_date: targetDate,
        meal: dragKey,
        owner: ownerKey,
        user_meal_by_date: userMealByDateKey
      })
    })
      .then(response => response.text())
      .then(Turbo.renderStreamMessage)
  }
}
